@use 'sass:color';

$color-base-blue: #51a7f0;
$color-base-dark-blue: #265174;
$color-base-light-blue: #6eb6f5;
$color-base-lighter-blue: #98e2fd;
$color-base: #757575;
$color-base-light: #bdbdbd;
$color-base-lighter: #e0e0e0;
$color-link: #446cb3;
$color-base-lightest: #f1f2f3;
$color-success: #198754;
$color-error: #b00020;
$color-warn: rgba(229, 197, 120, 0.4);
$color-accent: #c9435a;
$color-accent-text: #ffffff;
$color-accent-20: rgba(229, 197, 120, 0.2);
$color-accent-dark: color.adjust($color-accent, $lightness: -20%);
$color-accent-light: color.adjust($color-accent, $lightness: 10%);
$color-accent-lighter: color.adjust($color-accent, $lightness: 20%);
$gray-text: rgba(0, 0, 0, 0.6);
$color-disabled: rgba(0, 0, 0, 0.46);
//Icon's colors
$color-shamrock: #2ecc71;
$color-ebony-clay: #22313f;
$color-cinnabar: #e74c3c;
$bitcoin-accent: #f89f33;
//spacers
$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0,
  ),
  1: (
    x: (
      $spacer-x * 0.25,
    ),
    y: (
      $spacer-y * 0.25,
    ),
  ),
  2: (
    x: (
      $spacer-x * 0.5,
    ),
    y: (
      $spacer-y * 0.5,
    ),
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y,
  ),
  4: (
    x: (
      $spacer-x * 1.5,
    ),
    y: (
      $spacer-y * 1.5,
    ),
  ),
  5: (
    x: (
      $spacer-x * 3,
    ),
    y: (
      $spacer-y * 3,
    ),
  ),
);
$dialog-padding: 24px;

$layout-breakpoint-xs: 600px;
$layout-breakpoint-sm: 960px;
$layout-breakpoint-md: 1280px;
$layout-breakpoint-lg: 1920px;
