@charset "utf-8";
/* You can add global styles to this file, and also import other style files */

@use 'styles/vendor' as *;
@use 'styles/material-icons.scss' as *;
@use 'styles/roboto' as *;

body {
  overflow-y: hidden;
  background-color: var(--app-main-background);
  margin: 0;
}
