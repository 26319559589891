@use '@angular/material' as mat;
@use 'sass:color';
@use 'variables' as *;

$navigation-drawer-width: auto;
$mat-form-field-default-infix-width: 180px !default;

@include mat.elevation-classes();
@include mat.app-background();
@include mat.typography-hierarchy(mat.m2-define-typography-config());

$qt-mono: (
  100: color.adjust(white, $lightness: -90%),
  500: color.adjust(white, $lightness: -50%),
  600: color.adjust(white, $lightness: -40%),
  700: color.adjust(white, $lightness: -30%),
  800: white,
  contrast: (
    100: white,
    500: white,
    600: #545454,
    800: #545454,
  ),
);

$qt-accent: (
  100: color.adjust($color-accent, $lightness: 20%),
  200: color.adjust($color-accent, $lightness: 15%),
  300: color.adjust($color-accent, $lightness: 12%),
  400: color.adjust($color-accent, $lightness: 7%),
  500: color.adjust($color-accent, $lightness: 5%),
  600: $color-accent,
  700: color.adjust($color-accent, $lightness: -10%),
  800: color.adjust($color-accent, $lightness: -15%),
  900: color.adjust($color-accent, $lightness: -20%),
  contrast: (
    100: white,
    500: white,
    600: white,
    800: white,
  ),
);

$primary-light: mat.m2-define-palette($qt-mono, 600);
$accent-light: mat.m2-define-palette($qt-accent, 600);

$primary-dark: mat.m2-define-palette($qt-mono, 600);

$main-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-light,
      accent: $accent-light,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary-light,
      accent: $accent-light,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

:root {
  --app-main-background: #fafafa;
  --app-main-foreground: #ffffff;
  --app-main-text: #000000de;
  --app-secondary-text: #0000008a;
  --app-accent-text: #545454;
  --app-secondary-accent-text: #303030; //or #000000d0 from material
  --app-accent-contrast: #{$color-accent};
  --app-navigation-bg: #e6effd;
  --app-primary-border: #bfbfc3;
  --app-success-color: #198754;

  --scale_1_10: #8092ad;
  --scale_1_09: #89d997;
  --scale_1_08: #89d997;
  --scale_1_07: #93e1e1;
  --scale_1_06: #b8f7d4;
  --scale_1_05: #fff5e6;
  --scale_1_04: #fbfcdc;
  --scale_1_03: #f7e891;
  --scale_1_02: #f4d58b;
  --scale_1_01: #f4a64b;

  --color-cinnabar: #{$color-cinnabar};
  --color-shamrock: #{$color-shamrock};

  --mdc-theme-text-icon-on-background: var(--app-main-text);
  --mdc-icon-button-icon-color: var(--app-main-text);

  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes($main-theme);

  .darkTheme {
    --app-main-background: #545454;
    --app-main-foreground: #424242;
    --app-main-text: #fafafa;
    --app-secondary-text: #ffffff;
    --app-accent-text: #aeaeae;
    --app-secondary-accent-text: #fafafa;
    --app-accent-contrast: #{$color-accent};
    --app-navigation-bg: rgba(52, 152, 219, 0.2);
    --app-primary-border: #363636;

    --scale_1_10: #{color.adjust(#8092ad, $lightness: -30%)};
    --scale_1_09: #{color.adjust(#89d997, $lightness: -30%)};
    --scale_1_08: #{color.adjust(#89d997, $lightness: -30%)};
    --scale_1_07: #{color.adjust(#93e1e1, $lightness: -30%)};
    --scale_1_06: #{color.adjust(#b8f7d4, $lightness: -30%)};
    --scale_1_05: #{color.adjust(#fff5e6, $lightness: -30%)};
    --scale_1_04: #{color.adjust(#fbfcdc, $lightness: -30%)};
    --scale_1_03: #{color.adjust(#f7e891, $lightness: -30%)};
    --scale_1_02: #{color.adjust(#f4d58b, $lightness: -30%)};
    --scale_1_01: #{color.adjust(#f4a64b, $lightness: -30%)};

    --mdc-theme-text-icon-on-background: var(--app-main-text);
    --mdc-icon-button-icon-color: var(--app-main-text);

    @include mat.elevation-classes();
    @include mat.app-background();
    @include mat.all-component-colors($dark-theme);

    .mat-checkbox__checkmark-path {
      stroke: #ffffff !important;
    }

    .error-page {
      background-color: var(--app-main-background);
      color: var(--app-main-text);

      &__back-link {
        color: var(--app-accent-text);
      }
    }

    .mat-drawer-backdrop.mat-drawer-shown {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
      color: var(--app-main-text) !important;
    }

    .mat-calendar-body-selected {
      background: $color-accent !important;
      color: var(--app-main-text) !important;
    }

    .mat-mdc-row:hover {
      .mat-mdc-cell {
        color: var(--app-main-text) !important;
      }
    }

    .ql-toolbar .ql-stroke {
      fill: none;
      stroke: var(--app-main-text);
    }

    .ql-toolbar .ql-fill {
      fill: var(--app-main-text);
      stroke: none;
    }

    .ql-toolbar .ql-picker {
      color: var(--app-main-text);
    }

    .mat-mdc-card.mat-mdc-card-with-shadow {
      border: 1px solid #eee;
    }

    .tour-button__icon {
      color: var(--app-main-text);
    }

    .empty-state {
      color: var(--app-accent-text);
    }

    h2,
    p,
    a,
    small {
      color: var(--app-secondary-accent-text);
    }

    .mdc-data-table__header-cell {
      color: var(--app-accent-text);
    }

    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
      color: var(--app-main-text);
    }
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: var(--app-main-text);
  }

  .mdc-data-table__header-cell {
    color: var(--app-accent-text);
  }

  .mat-mdc-option.mdc-list-item--selected > .mdc-list-item__primary-text {
    color: var(--app-main-text);
    z-index: 1;
  }

  h2 {
    color: var(--app-accent-text);
  }

  .empty-state {
    color: #bbbbbb;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    padding: unset;
    box-sizing: content-box;
    // border-bottom: 1px solid mat.get-color-from-palette($primary-light, divider);

    &:not(:first-child) {
      padding-left: 6px;
    }
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: 20px;
  }

  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 24px;
  }

  .mat-mdc-tab-group.mat-primary .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-nav-bar.mat-primary .mdc-tab-indicator__content--underline {
    border-color: $color-accent !important;
  }

  .mat-mdc-tab-group:not(.mat-mdc-tab-group-stretch-tabs)
    > .mat-mdc-tab-header
    .mat-mdc-tab,
  .mat-mdc-tab-header:not(.mat-mdc-tab-nav-bar-stretch-tabs) .mat-mdc-tab-link {
    min-width: 160px;
  }

  mat-form-field.mat-mdc-form-field {
    &.mat-focused {
      .mat-mdc-floating-label {
        color: var(--app-accent-text) !important;
      }
      .mdc-line-ripple:after {
        border-color: var(--app-accent-text) !important;
      }
    }
    .mat-mdc-floating-label,
    .mat-mdc-floating-label.mat-focused,
    .mat-mdc-form-field.mat-focused.matform-field-should-float {
      color: var(--app-accent-text);
    }
    .mdc-line-ripple:before,
    .mdc-line-ripple:before.mat-focused {
      border-color: var(--app-accent-text) !important;
    }

    .mdc-text-field--outlined {
      color: var(--app-main-text);
    }
  }

  .mat-mdc-input-element {
    caret-color: var(--app-accent-text) !important;
  }
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-snack-bar-container.qt-error {
  .mdc-snackbar__surface {
    background-color: $color-error;
  }
}
